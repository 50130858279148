<template>
  <b-container fluid class="navbar-container navbar--logged">
    <b-navbar toggleable="md" class="main-navbar bg-white px-0">
      <b-button
        variant="link"
        class="navbar-wallet d-md-none me-auto p-0 text-no-decoration"
        @click="routeTo('/wallet')"
        v-if="extendedStatus.userStatus !== 'wait' && isActiveUser && isOperative"
      >
        <font-awesome-icon
          icon="wallet"
          class="fa-fw navbar-wallet__icon"
        />
        <div class="navbar-wallet__content text-left">
          <h6 class="mb-0">Credito disponibile</h6>
          <p class="mb-0">{{walletValue}}</p>
        </div>
      </b-button>
      <b-button
        v-b-toggle.sidebar-nav
        variant="link"
        class="d-none d-md-inline-block"
      >
        <font-awesome-icon
          icon="bars"
          class="fa-fw"
        />
      </b-button>
      <div class="logo-top-container-new" v-on:click.alt="viewVersion">
        <router-link to="/" class="navbar-brand m-0 p-0 d-none d-md-inline-block">
          <img :src="require('../../assets/img/logo-hor-MFSOLVENCY.png')" class="logo-top p-0">
        </router-link>
        <router-link to="/" class="navbar-brand m-0 p-0 d-md-none">
          <img :src="require('../../assets/img/logo_badge.png')" class="logo-top p-0">
        </router-link>
      </div>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <nav-items vertical classes="d-block d-md-none" />
        <b-navbar-nav>
          <b-nav-item class="d-md-flex align-items-center" link-classes="py-0">
            <user-switcher classes="navbar-switcher" />
          </b-nav-item>
          <b-nav-item
            v-on:click="routeTo('/wallet')"
            class="d-none d-md-block"
            v-if="extendedStatus.userStatus !== 'wait' && isActiveUser && isOperative"
          >
            <div class="navbar-wallet">
              <font-awesome-icon
                icon="wallet"
                class="fa-fw navbar-wallet__icon"
              />
              <div class="navbar-wallet__content">
                <h6 class="mb-0">Credito disponibile</h6>
                <p class="mb-0">{{ walletValue }}</p>
              </div>
            </div>
          </b-nav-item>
          <b-nav-item v-on:click="routeTo('/user-data')" class="d-none d-md-flex">
            <font-awesome-icon
              icon="gears"
              class="fa-fw"
            />
          </b-nav-item>
          <b-nav-item v-on:click="submitLogout" class="d-none d-md-flex border-right-0">
            <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" class="fa-fw" />
          </b-nav-item>
          <b-nav-item class="d-md-none mt-3" link-classes="d-flex justify-content-between">
            <b-button
              @click="routeTo('/user-data')"
              variant="link"
            >
              <font-awesome-icon icon="gears" class="fa-fw" />
            </b-button>
            <b-button
              @click="submitLogout"
              variant="link"
            >
              <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" class="fa-fw" />
            </b-button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </b-container>
</template>

<script>
import { extractErrorMessage, isNotEmpty } from '@/utils/validators';
import { toastInformation } from '@/utils/toast';
import { version, createPublicUri } from '@/utils/utilities';
import { formatCurrency } from '../../utils/formatterHelper';

const UserSwitcher = () => import('@/components/userSwitcher/UserSwitcher.vue');
const NavItems = () => import('./NavItems.vue');

export default {
  name: 'HeaderLogged',
  components: {
    NavItems,
    UserSwitcher,
  },
  data() {
    return {
      submitted: false,
      message: null,
      // eslint-disable-next-line global-require
      userPlaceholder: require('@/assets/img/user-img_placeholder.png'),
    };
  },
  computed: {
    isLogged() {
      return this.$store.getters['auth/isLogged'];
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    profileImgUrl() {
      // const imageProfile = this.$store.getters['subject/getImage']('PROFILE');
      const imageProfile = this.$store.getters['subject/getPersonImage']('PROFILE');
      return isNotEmpty(imageProfile)
        ? createPublicUri(imageProfile.documentUpload.externalId)
        : this.userPlaceholder;
    },
    profileImgStyle() {
      return `background-image: url(${this.profileImgUrl});`;
    },
    walletValue() {
      if (isNotEmpty(this.$store.getters['wallet/getWallet']?.amount)) {
        const a = this.$store.getters['wallet/getWallet']?.amount.amount;
        const b = this.$store.getters['wallet/getWallet']?.amount.currency;
        return formatCurrency(a, 'it', b);
      }
      return 'N.D.';
    },
    extendedStatus() {
      return this.$store.getters['subject/currentExtendedStatus'];
    },
    isActiveUser() {
      return this.$store.getters['subject/isActiveUser'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
  },
  methods: {
    submitLogout() {
      this.$confirm(
        {
          message: "Stai per abbandonare l'area riservata, confermi?",
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              this.$store.dispatch('auth/logout').then(() => {
                if (this.$route.path !== '/') this.$router.replace('/');
                this.submitted = false;
              },
              (error) => {
                this.message = extractErrorMessage(error);
                this.submitted = false;
              });
            }
          },
        },
      );
    },
    viewVersion() {
      console.log('viewVersion');
      toastInformation(`Version v. ${version}`);
    },
    routeTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
